import React, { useEffect, useState, useRef } from "react";
import NavbarOne from '../siteTheme/NavbarOne';
import Config from "../../core/config";
import { currencyToken, coinToBasecoin } from "../../core/helper/common";
import { makeRequest } from "../../core/services/v1/request";
import { toast } from "../../core/lib/toastAlert";
import Modal from 'react-bootstrap/Modal';
import line from "../../assets/images/bg_coin_line.jpg";
export default function Convert(props) {
    let myTimeout = useRef();

    const [currencyModel, setCurrencyModel] = useState({ show: false, type: "from", list: [], filterList: [] });
    const [requestForm, setrequestForm] = useState({
        "type": "request", // request | order
        "newCur": false,
        "fromCurrency": {},
        "toCurrency": {},
        "fromBalance": {},
        "toBalance": {},
        "network": "",
        "address": "",
        "addressDetails": "",
        "amount": 0,
        "amountSecond": "",
        "reqCall": true,
        "buyPrice": 0,
        "sellPrice": 0
    });

    const [supportedBaseCoin, setSupportedBaseCoin] = useState([]);
    const [loadingSta, setLoadingSta] = useState(false);
    const [searchCoinText, setSearchCoinText] = useState("");
    const [fromCurrencyList, setFromCurrencyList] = useState([]);
    const [toCurrencyList, setToCurrencyList] = useState([]);
    const [currencyBal, setcurrencyBal] = useState({});
    const [pairDetails, setpairDetails] = useState({});

    const [error_msg, seterror_msg] = useState({ "fromBalance": "", "toBalance": "" });

    // useEffect(() => {
    //     if (requestForm && requestForm.amount != undefined && requestForm.amountSecond != undefined) {
    //         setTimeout(function () {
    //             myTimeout.current = submitOrRequestLiqu("request", requestForm.amount);
    //         }, 1000);
    //         return () => clearTimeout(myTimeout.current);
    //     }
    //     console.log({reqCall: requestForm.reqCall});
    // }, [requestForm]);
    
    useEffect(() => {
        if (requestForm && requestForm.fromCurrency != undefined && requestForm.toCurrency != undefined && requestForm.fromCurrency.currencyId != undefined && requestForm.toCurrency.currencyId != undefined) {
            if(pairDetails.pair == undefined || pairDetails.pair != requestForm.fromCurrency.currencySymbol+"_"+requestForm.toCurrency.currencySymbol) {
                getPairDetails();
            }
        }
    }, [requestForm]);
    useEffect(() => {
        // getconvertPair();
        getSupportedBaseCoin();
    }, []);

    // async function getconvertPair() {
    //     try {
    //         const params = {
    //             url: `${Config.V2_API_URL}convert/pair/list`,
    //             method: 'GET',
    //         }
    //         const response = await makeRequest(params);
    //         if (response.status) {
    //             if (response.list.length > 0) {
    //                 setrequestForm({
    //                     "type": "request", // request | order
    //                     "newCur": false,
    //                     "fromCurrency": response.list[0].fromCurrency,
    //                     "toCurrency": response.list[0].toCurrency,
    //                     "fromBalance": {},
    //                     "toBalance": {},
    //                     "network": response.list[0].fromCurrency.basecoin,
    //                     "address": response.list[0].fromCurrency.contractAddress,
    //                     "addressDetails": "",
    //                     "amount": 0,
    //                     "amountSecond": "",
    //                     "reqCall": true
    //                 })
    //                 setFromCurrencyList(response.list);
    //                 setToCurrencyList(response.list);
    //                 await currencyBalance(response.list[0].fromCurrency.currencyId, response.list[0].fromCurrency.currencySymbol);
    //                 await currencyBalance(response.list[0].toCurrency.currencyId, response.list[0].toCurrency.currencySymbol);
    //             }
    //         }
    //     } catch (err) { }
    // }
    async function getSupportedBaseCoin() {
        try {
            const params = {
                url: `${Config.V1_API_URL}currency/getSupportedBaseCoin`,
                method: 'GET'
            }
            const response = await makeRequest(params);
            if (response.status && response.data) {
                setSupportedBaseCoin(response.data);
                getAllCurrency(response.data[0]);
            }
        }
        catch (err) {
            console.log(err);
        }
    }
    async function getAllCurrency(basecoin = 'BEP20') {
        try {
            const params = {
                url: `${Config.V1_API_URL}currency/getCurrencyfilter`,
                method: 'POST',
                body: {
                    formvalue: {
                        from: "user",
                        coinOrToken: "token",
                        basecoin
                    }
                }
            }
            const response = await makeRequest(params);
            if (response.status && response.getCurrencyTblDetails) {
                if (response.getCurrencyTblDetails.length > 0) {
                    const fromFilterVal = await response.getCurrencyTblDetails.filter(currency => currency.currencySymbol !== "USDT");
                    const toFilterVal = await response.getCurrencyTblDetails.filter(currency => currency.currencySymbol === "USDT");
                    const network = (fromFilterVal[0].basecoin == "Coin") ? coinToBasecoin(fromFilterVal[0].currencySymbol) : fromFilterVal[0].basecoin;
                    setrequestForm({
                        "type": "request", // request | order
                        "newCur": false,
                        "fromCurrency": fromFilterVal[0],
                        "toCurrency": toFilterVal[0],
                        "fromBalance": {},
                        "toBalance": {},
                        "network": network,
                        "address": fromFilterVal[0].contractAddress,
                        "addressDetails": "",
                        "amount": 0,
                        "amountSecond": "",
                        "reqCall": true,
                        "buyPrice": 0,
                        "sellPrice": 0
                    })
                    setFromCurrencyList(fromFilterVal);
                    setToCurrencyList([toFilterVal[0]]);
                    await currencyBalance(fromFilterVal[0].currencyId, fromFilterVal[0].currencySymbol);
                    await currencyBalance(toFilterVal[0].currencyId, toFilterVal[0].currencySymbol);
                }
            }
        } catch (err) { }
    }
    async function getPairDetails() {
        try {
            const params = {
                url: `${Config.V2_API_URL}convert/pair/list/select`,
                method: 'POST',
                body: {
                    currencyIdA: requestForm.fromCurrency.currencyId,
                    currencyIdB: requestForm.toCurrency.currencyId,
                    type: "pairSearch"
                }
            }
            const response = await makeRequest(params);
            if (response.status && response.list && response.list[0]) {
                setpairDetails(response.list[0]);
            }
            else {
                setpairDetails({});
            }
        } catch (err) { }
    }
    async function currencyBalance(currencyId, currencySymbol = "") {
        try {
            if(currencyId) {
                const params = {
                    url: `${Config.V1_API_URL}wallet/getCurrencyBalance`,
                    method: 'POST',
                    body: { currencyId: currencyId }
                }
                const response = await makeRequest(params);
                if (response.status && response.data) {
                    setcurrencyBal(prevState => ({
                        ...prevState,
                        [currencySymbol]: (response.data.liqAmount) ? response.data.liqAmount : 0
                    }));
                }
            }
        } catch (err) { }
    }

    const inputChange = (event) => {
        if(event.target.id == "amount" || event.target.id == "amountSecond") {
            if(isNaN(event.target.value)) {
                return false;
            }
        }
        if(event.target.id == "addressSearch") {
            let searchText = event.target.value?.trim().toLowerCase();
            setSearchCoinText(searchText);
            var filterList = [];
            currencyModel.list.forEach((val, key) => {
                if (
                    (currencyModel.type === "from" && val.currencyName.toLowerCase().search(searchText) !== -1) ||
                    (currencyModel.type === "to" && val.currencyName.toLowerCase().search(searchText) !== -1)
                ) {
                    filterList.push(val);
                }
            });
            setCurrencyModel((prevState) => ({
                ...prevState,
                filterList: filterList
            }));
            if(filterList.length == 0) {
                setrequestForm(prevState => ({
                    ...prevState,
                    address: searchText,
                    addressDetails: {},
                    reqCall: true
                }));
                submitOrRequestLiqu("addressSearch", requestForm.amount, searchText);
            }
        }
        else if(event.target.id == "network") {
            if(event.target.getAttribute("value")) {
                getAllCurrency(event.target.getAttribute("value"));
            }
        } else {
            if(event.target.id == "amount" && pairDetails && pairDetails.sellPrice > 0) {
                setrequestForm(prevState => ({
                    ...prevState,
                    [event.target.id]: event.target.value,
                    amountSecond: (event.target.value * pairDetails.sellPrice),
                    reqCall: true
                }));
            }
            else {
                setrequestForm(prevState => ({
                    ...prevState,
                    [event.target.id]: event.target.value,
                    reqCall: true
                }));
            }            
        }
    }

    async function selectPairList(val, type) {
        if (type === "fromSearch") {
            const network = val.basecoin ? ((val.basecoin == "Coin") ? coinToBasecoin(val.currencySymbol) : val.basecoin) : requestForm.network;
            // await currencyBalance(val.currencyId, val.currencySymbol);
            setrequestForm(prevState => ({
                ...prevState,
                fromCurrency: val,
                network,
                reqCall: false
            }));
            submitOrRequestLiqu("request");
        }
        else if (type === "from" || type === "to") {
            const network = (val.basecoin == "Coin") ? coinToBasecoin(val.currencySymbol) : val.basecoin;
            await currencyBalance(val.currencyId, val.currencySymbol);
            setrequestForm(prevState => ({
                ...prevState,
                [type+"Currency"]: val,
                network,
                reqCall: false
            }));
        }
        setCurrencyModel({
            show: false, type: "", list: [], filterList: []
        });
    }
    async function submitOrRequestLiqu(type = "request", amount = 0, address = "") {
        // console.log({type, amount, address, requestForm});
        try {
            address = address ? address : requestForm.address;
            if ((address || requestForm.fromCurrency.basecoin == "Coin") && (requestForm.reqCall || type === "addressSearch")) {
                let reqData = {};
                if(type == "order") {
                    reqData = {
                        "address": address ? address : requestForm.address,
                        "network": requestForm.network,
                        "amount": amount !== undefined ? amount : requestForm.amount,
                        "amountSecond": requestForm.amountSecond,
                        "type": type,
                        "currencySymbol": requestForm.fromCurrency.currencySymbol
                    }
                }
                else {
                    reqData = {
                        "address": address ? address : requestForm.address,
                        "network": requestForm.network,
                        "amount": amount !== undefined ? amount : requestForm.amount,
                        "type": type,
                        "currencySymbol": requestForm.fromCurrency.currencySymbol
                    }
                }
                let reqUrl = `${Config.V2_API_URL}launchpad/contract`;
                if(requestForm.amount > 0 && (type != "request" && type != "addressSearch")) {
                    reqUrl = reqUrl+"/add";
                }
                const params = {
                    url: reqUrl,
                    method: 'POST',
                    body: reqData
                }
                setLoadingSta(true);
                const response = await makeRequest(params);
                setLoadingSta(false);
                let {
                    message: respMessage = "",
                    data: respData = "",
                    status: respStatus = false
                } = response;
                const errorType = respStatus ? "success" : "error";
                if (type === "request" || type === "addressSearch") {
                    // if (response.status) {
                        if(response.contDet) {
                            if(respData.newCur == true) {
                                response.contDet.network = reqData.network;
                                setrequestForm(prevState => ({
                                    ...prevState,
                                    // fromCurrency: response.contDet,
                                    addressDetails: response.contDet,
                                    reqCall: false
                                }));
                                if(respData.bal && respData.bal.liqAmount != undefined) {
                                    setcurrencyBal(prevState => ({
                                        ...prevState,
                                        [response.contDet.currencySymbol]: respData.bal.liqAmount
                                    }));
                                }
                            }
                        }
                    // }
                    seterror_msg({ "balance": respMessage });
                }
                // if(reqData.amount > 0 && reqData.amountSecond > 0) {
                // }
                if(respMessage) {
                    if (response.status) {
                        toast({ errorType, message: respMessage });
                    }
                    else {
                        toast({ errorType, message: respMessage });
                    }
                }
                if (type === "order") {
                    if (response.status) {
                        if(requestForm.fromCurrency && requestForm.fromCurrency.currencyId) {
                            await currencyBalance(requestForm.fromCurrency.currencyId, requestForm.fromCurrency.currencySymbol);
                        }
                        await currencyBalance(requestForm.toCurrency.currencyId, requestForm.toCurrency.currencySymbol);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    async function handleClose() {
        setCurrencyModel({
            show: false, type: "", list: [], filterList: []
        });
    }
    return (
        <div>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
                pageName="Liquidity"
            />
            <div className="under-line-effect">
                <img src={line} alt="bg_coin_line" />
            </div>
            <div className="earn-banner-container">
                <div className="row earn-banner-inside mx-auto">
                    <div className="col-lg-12">
                        <div className="earn-header">{Config.SITENAME}</div>
                        <div className="earn-content">Earn from LP</div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-5 convert-main-container">
                    <div className="row convet-container">
                        <div className="col-lg-12">
                            <div className="row mb-4">
                                <div className="col-lg-6">
                                    <span className="convert-header">Choose Token Pair</span>
                                    {/* {JSON.stringify(pairDetails)} */}
                                    {/* {JSON.stringify(requestForm)} */}
                                    {/* -{requestForm.network} */}
                                </div>
                                <div className="col-lg-6">
                                    {/* {
                                    (supportedBaseCoin && supportedBaseCoin.length > 0) ?
                                    <select className="select-dropdown-btn" id="network" value={requestForm.network ? requestForm.network : ""} onChange={inputChange}>
                                        {
                                            supportedBaseCoin.map((val, key) => {
                                                return <option >{val}</option>
                                            })
                                        }
                                    </select>:""} */}
                                    {(supportedBaseCoin && supportedBaseCoin.length > 0)?
                                    <div className="border p-2 dropdown custom-dropdown status_dropdown" data-color="created-status">
                                        <button className="select-dropdown-btn dropdown-toggle w-100 d-flex align-items-center justify-content-between status__btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,12">
                                            {requestForm.network}
                                        </button>
                                        <ul className="dropdown-menu status_change" aria-labelledby="dropdownMenuButton1" >
                                            {
                                                supportedBaseCoin.map((val, key) => {
                                                    return <li key={key}><a onClick={inputChange} id="network" value={val} className="dropdown-item" data-class="oco" href="#">{val}</a></li>
                                                })
                                            }
                                        </ul>
                                    </div>:""}
                                    {/* {JSON.stringify(requestForm)} */}
                                </div>
                            </div>
                            <div className="row from-container mb-3">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-4 from-label">
                                            <span>Pair A</span>
                                        </div>
                                        <div className="col-lg-8 from-tranfer-container">
                                            <span>Available:
                                                <span>
                                                    {currencyBal[requestForm.fromCurrency.currencySymbol] != undefined ? currencyBal[requestForm.fromCurrency.currencySymbol].toFixed(requestForm.toCurrency ? requestForm.toCurrency.siteDecimal : 2) : 0}
                                                    {requestForm.fromCurrency.currencySymbol}
                                                </span>
                                                {/* <span className="deposit-transfer">Deposit Transfer</span> */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 from-img-container">
                                        <div className="col-lg-1 col-2 coin-price-container">
                                            <span>
                                                <img src={requestForm.fromCurrency.image} className="convert-icon" />
                                            </span>
                                        </div>
                                        <div className="col-3">
                                            <div>
                                                {requestForm.fromCurrency.currencySymbol}
                                                <span className="convert-popup-image dropdown-toggle" onClick={() => { setCurrencyModel({ show: true, type: "from", list: fromCurrencyList, filterList: fromCurrencyList }); }}>
                                                    {/* &#11205; */}
                                                </span>
                                            </div>
                                            <div className="convert-small-label mt-2">{requestForm.fromCurrency.currencyName}</div>
                                        </div>
                                        <div className="col-lg-8 col-7 coin-price-container">
                                            <span className="convert-small-label swap-data">
                                                <input placeholder="Enter Pair-A Amount" className="coin-count" id="amount" value={requestForm.amount ? requestForm.amount : ""} onChange={inputChange} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {error_msg && <p className="error_msg">{error_msg.fromBalance} </p>}

                            <div className="row from-container mb-3">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-4 from-label">
                                            <span>Pair B</span>
                                        </div>
                                        <div className="col-lg-8 from-tranfer-container">
                                            <span>Available:
                                                <span>
                                                    {currencyBal[requestForm.toCurrency.currencySymbol] != undefined ? currencyBal[requestForm.toCurrency.currencySymbol].toFixed(requestForm.toCurrency ? requestForm.toCurrency.siteDecimal : 2) : 0}
                                                    {requestForm.toCurrency.currencySymbol}
                                                </span>
                                                {/* <span className="deposit-transfer">Deposit Transfer</span> */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 from-img-container">
                                        <div className="col-lg-1 col-2 coin-price-container">
                                            <span>
                                                <img src={requestForm.toCurrency.image} className="convert-icon" alt="img" />
                                            </span>
                                        </div>
                                        <div className="col-3">
                                            <div>
                                                {requestForm.toCurrency.currencySymbol}
                                                <span className="convert-popup-image dropdown-toggle" onClick={() => {
                                                    // return false;
                                                    setCurrencyModel({ show: true, type: "to", list: toCurrencyList, filterList: toCurrencyList }); }}>
                                                    {/* &#11205; */}
                                                </span>
                                            </div>
                                            <div className="convert-small-label mt-2">{requestForm.toCurrency.currencyName}</div>
                                        </div>
                                        <div className="col-lg-8 col-7 coin-price-container">
                                            <span className="convert-small-label swap-data">
                                                <input placeholder={pairDetails && pairDetails.buyPrice > 0 ? "Pair-B Amount" : "Enter Pair-B Amount"} className="coin-count" id="amountSecond" value={requestForm.amountSecond ? requestForm.amountSecond : ""} onChange={inputChange} disabled={pairDetails && pairDetails.buyPrice > 0 ? true :  false} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {error_msg && <p className="error_msg">{error_msg.toBalance} </p>}

                            {(requestForm.amount > 0 && requestForm.amountSecond > 0) ? 
                            <div className="row">
                                <div className="col-lg-4 p-0">
                                    <div className="line"></div>
                                </div>
                                <div className="col-lg-4 coin-to-coin">
                                    <span>
                                        1 {" "}
                                        {requestForm.fromCurrency.currencySymbol + " "}
                                        ≈
                                        {" "}
                                        {(requestForm.amountSecond/ requestForm.amount).toFixed(requestForm.fromCurrency.siteDecimal)} {" "}
                                        {requestForm.toCurrency.currencySymbol}
                                    </span>
                                </div>
                                <div className="col-lg-4 p-0">
                                    <div className="line"></div>
                                </div>
                            </div>:""}
                            <div className="row mt-4 justify-content-center">
                                <button type="button" className={((requestForm.amount <= 0 || requestForm.amountSecond <= 0 || loadingSta) ? "col-lg-4 convert-btn disabledBtn" : "col-lg-4 convert-btn")}
                                    onClick={() => submitOrRequestLiqu("order", requestForm.amount)}
                                    disabled={(requestForm.amount <= 0 || requestForm.amountSecond <= 0 || loadingSta) ? true : false}
                                >
                                    {loadingSta ? <span>Loading</span> : <span>Liquidity</span> }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={currencyModel.show === true} onHide={() => {
                handleClose();
                setSearchCoinText("");
            }}>
                <Modal.Body> <div className="row dropdown-container">
                    <div className="row mb-3">
                        <div className="col-11 p-0">
                            <span>Select Currency-{requestForm.network}</span>
                        </div>
                        <div className="col-1 p-0 coin-popup-close">
                            <div onClick={() => {
                                handleClose();
                                setSearchCoinText("");
                            }}>X</div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row justify-content-center popup-search">
                            <div className="col-12 p-0">
                                <input placeholder="Search" className="search-text" id="addressSearch" value={searchCoinText} onChange={inputChange} />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="search-dropdown-container">
                            {
                                currencyModel.filterList.map((val, key) => {
                                    // const data = val[currencyModel.type + "Currency"];
                                    const data = val;
                                    return (
                                        <div
                                            className="row justify-content-center convert-list"
                                            onClick={() => selectPairList(val, currencyModel.type)}
                                            key={key}
                                        >
                                            <div className="col-2 coin-price-container">
                                                <img src={data.image} className="convert-icon-popup" alt="icon" />
                                            </div>
                                            <div className="col-10">
                                                <div>{data.currencySymbol}</div>
                                                <div className="convert-small-label mt-2">{data.currencyName}</div>
                                            </div>
                                            {/* <div className="col-1"> */}
                                            {/* <div>{data.count}</div>
                                                <div className="convert-small-label mt-2">{data.currency}{val.price}</div> */}
                                            {/* </div> */}
                                        </div>
                                    )
                                })
                            }
                            {/* div className="row justify-content-start convert-list" */}
                            {
                                currencyModel.filterList.length === 0 ? <>
                                    {
                                        currencyModel.type == "from" ?
                                            (requestForm.addressDetails && requestForm.addressDetails.currencySymbol)
                                            ?
                                            <div
                                                className="row justify-content-center convert-list"
                                                onClick={() => selectPairList(requestForm.addressDetails, "fromSearch")}
                                            >
                                                <div className="col-2 coin-price-container">
                                                    {(requestForm.addressDetails.image) ? <img src={requestForm.addressDetails.image} className="convert-icon-popup" alt="icon" /> : ""}
                                                </div>
                                                <div className="col-10">
                                                    <div>{requestForm.addressDetails.currencySymbol}</div>
                                                    <div className="convert-small-label mt-2">{requestForm.addressDetails.currencyName}</div>
                                                </div>
                                            </div>
                                            :
                                            (loadingSta) ? "" :
                                            <>
                                                <div className="col--1"></div>
                                                <div className="col-5">Contract not matched</div>
                                            </>
                                        :
                                        <>
                                            <div className="col--1"></div>
                                            <div className="col-5">Data not matched</div>
                                        </>
                                    }
                                </> : <></>
                            }
                        </div>
                    </div>
                </div></Modal.Body>
            </Modal>
        </div>
    );
}
