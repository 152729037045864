import v2serviceUrl from "./v2/serviceUrl";
import { makeRequest } from "./v1/request";

export const getTokenCreatePackageList_API = async (data) => {
  const requestData = { method: "get", url: v2serviceUrl.tokenCreatePackageList };
  const resp = await makeRequest(requestData);
  return resp;
};

export const getTokenCreateRequest_API = async (data) => {
  const requestData = { method: "post", url: v2serviceUrl.tokenCreateRequest, data: data.payload };
  const resp = await makeRequest(requestData);
  return resp;
};