import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import noreplay from "../../assets/images/deposit/no-re.png";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config/";
import { dateFormat } from '../../core/helper/date-format';
import { toast } from "../../core/lib/toastAlert";
import { RiFileCopyFill } from "react-icons/ri";

import $ from "jquery";
import Pagination from 'react-responsive-pagination';
import '../../pagination.css';

import Modal from 'react-bootstrap/Modal';

export default function TransactionhistoryTokenCreate(props) {
  const navigate = useNavigate();
  const [transHistory, settransHistory] = useState([]);
  const [filter, setFilter] = useState({
    tab: 'tokenCreateRequest',
    type: 'tokenCreateRequest'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [totalorders, settotalorders] = useState(0);
  const [transBonusModal, setTransBonusModal] = useState({show: false, list: []});

  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      const generateStatusClass = `${$(this).attr("data-class")}-status`;
      $(this)
        .closest(".status_dropdown")
        .attr("data-color", `${generateStatusClass}`);
    });
  }, []);

  useEffect(() => {
    getHistory();
  }, [filter]);

  async function getHistory() {
    try {
      const params = { 
        url: `${Config.V1_API_URL}wallet/getHistoryWithFilter`,
        method: 'POST',
        data: {filter}
      }
      const response = (await makeRequest(params));
      if (response.status) {
        settransHistory(response.data)
        settotalorders(response.total);
      }
    } catch(err){}
  }
  const noOfPages = Math.ceil(totalorders / recordsPerPage);
  const clickPageNo = (pgNumber) => {
    pageChange(pgNumber);
  }
  const pageChange = (newCurrentPage) => {
    if (newCurrentPage >= 1 && newCurrentPage <= noOfPages) {
      setCurrentPage(newCurrentPage);
      getPagination(newCurrentPage)
    }
  }  
  async function getPagination(page) {
    try {
      const data = {
        limit: recordsPerPage,
        offset: recordsPerPage * (page - 1),
        filter
      };
      const params = {
        url: `${Config.V1_API_URL}wallet/getTransHistory?`+ data,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        settransHistory(response.data);
        settotalorders(response.total);
      }
    } catch (err) { }
  }
  async function cancelTrans(data) {
    try {
      const payload = {
        _id: data._id
      };
      const params = {
        url: `${Config.V1_API_URL}wallet/getTransHistoryDetails`,
        method: "POST",
        data: payload
      };
      const response = await makeRequest(params);
      if (response.status && response.transBonus) {
        setTransBonusModal({
          show: true, list: response.transBonus
        });
      }
    } catch (err) { }
  }

  async function handleClose(){
    setTransBonusModal({
      show: false, list: []
    });
  }

  function copyText(data = {}) {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.value = data.text;
    input.select();
    document.execCommand("Copy");
    input.remove();
    let message = "";
    if(data.type == "TxnId") {
      message = "Txn id copied successfully!";
    }
    toast({ type: 'success', message });
  }

  return (<>
    <div className="transaction-history-second-section py-3 ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="d-flex flex-row mt-3 transaction-history-select-section flex-wrap gap-3">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container deposit-fourth-section">
      <Modal show={transBonusModal.show === true} onHide={()=>handleClose()} className="modal-popup">
        <Modal.Body>
          <div className="row dropdown-container">
            <div className="col-11">
              Interest History
            </div>
            <div className="col-1 close-btn" onClick={()=>handleClose()}>
              X
            </div>
          </div>
          <hr></hr>
          <table className="table mt-4">
            <thead>
              <tr>
                <th>Date</th>
                <th>Interest</th>
              </tr>
            </thead>
            <tbody>
              {
                transBonusModal && transBonusModal.list && transBonusModal.list.length ?
                transBonusModal.list.map((item, i) => {
                  return <tr key={i}>
                    <td data-label="createdDate">{item.bonusDate}</td>
                    <td data-label="createdDate">{(item.bonus).toFixed(4)} {item.currency}</td>
                  </tr>
                }) : <tr >
                <td></td>
                <td>Record Not Found</td>
                <td></td>
              </tr>
              }
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <div className="row align-items-center">
        <div className="col-lg-12">
          <table className="table mt-4">
            <thead>
              <tr>
                <th>Trans Date</th>
                <th>Package</th>
                <th>Price</th>
                <th>Access</th>
                <th>Token Name</th>
                <th>Address</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {(transHistory && transHistory.length > 0) ? transHistory.map((item, i) => {
                return <tr key={i}>
                  <td data-label="createdDate">
                    {dateFormat(item.createdDate)}
                  </td>
                  <td data-label="package">
                    {(item.otherData && item.otherData.selectedPackage) ? item.otherData.selectedPackage.packageName : ""}
                  </td>
                  <td data-label="price">
                    {(item.otherData && item.otherData.selectedPackage) ? item.otherData.selectedPackage.price : 0}
                  </td>
                  <td data-label="accessDetails">
                    {(item.otherData && item.otherData.selectedPackage) ? item.otherData.selectedPackage.accessDetails : 0}
                  </td>
                  <td data-label="tokenName">
                    {(item.otherData && item.otherData.userRequest) ? item.otherData.userRequest.name : 0}
                  </td>
                  <td data-label="TxID">
                    { item.txnId != "" ? <p onClick={() => copyText({
                      type: "TxnId",
                      text: item.txnId
                    })}>
                    <RiFileCopyFill className="dest-addres-link" />
                    <label className="dest-addres-ellipsis">
                      {item.txnId}
                    </label></p> : "-"}
                  </td>
                  <td data-label="status">
                    {item.status == 0 ? 'Pending' : item.status == 1 ? "Approved" : item.status == 2 ? 'Rejected' :'Processing' }
                  </td>
                </tr>
                }) : <tr className="no-records-found">
                <td></td>
                <td></td>
                <td>
                  <img
                    className="no-record-image"
                    src={noreplay}
                    alt="no-record"
                  />
                  <br />
                  <span className="text-center">No Records Found</span>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr> }
            </tbody>
          </table>
          {totalorders > recordsPerPage &&
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-end">
                <Pagination className="p2p-trade-pagination"
                  total={Math.ceil(totalorders / recordsPerPage)}
                  current={currentPage}
                  onPageChange={page => clickPageNo(page)}
                />
              </div>
            </div>
            }
          {/* {(transHistory && transHistory.length > 10) &&
          <div className="d-flex flex-row mt-4 mb-5">
            <div className="mx-auto">
              <button type="button" className="table-view-all-deposit-button-1">
                View More{" "}
                <BsFillArrowRightCircleFill className="view-more-icon" />
              </button>
              <button type="button" className="table-view-all-deposit-button-2">
                View Less{" "}
                <BsFillArrowRightCircleFill className="view-more-icon" />
              </button>
            </div>
          </div>} */}
        </div>
      </div>
    </div>
  </>
  );
}